import config from '@haaretz/l-config';

import type { HtzAppConfig } from '@haaretz/l-app-config/types';
import type { ConnectionType, Env } from '@haaretz/s-types';
import type { LinkProps as NextLinkProps } from 'next/link';
export type Url = NextLinkProps['href'];

export const supportedPageTypes: HtzAppConfig<Env, ConnectionType>['supportedNextUrl'] =
  config.get('supportedNextUrl');

export default function isNextLink(href: Url): boolean {
  const stringHref = typeof href === 'string' ? href : href.href;
  // 1. External URL are always non-Next.Js
  if (!stringHref || stringHref.startsWith('http')) return false;

  return supportedPageTypes.some(supportedPage =>
    supportedPage === '/' ? stringHref === '/' : stringHref.includes(supportedPage)
  );
}
